import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/sales/booking', pathMatch: 'full' },

  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./main-layout/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'sales',
    loadChildren: () => import('./main-layout/sales/sales.module').then(m => m.SalesModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'accounts',
    loadChildren: () => import('./main-layout/accounts/accounts.module').then(m => m.AccountsModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'manage',
    loadChildren: () => import('./main-layout/manage/manage.module').then(m => m.ManageModule),
    canActivate:[AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
