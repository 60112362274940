import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './login.model';
import { RestService } from '../../services/common/rest.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../../services/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  loginUserData: User = { email: '', password: '' };
  errorMessage: string = '';
  show_button: Boolean = false;
  show_eye: Boolean = false;
  constructor(private router: Router, private restService: RestService, private commonService:CommonService) {

  }

  ngOnInit() {

  }

  //Function
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }



  Submit() {
    this.restService.postData('user/login', this.loginUserData).subscribe(data => {
      this.commonService.setLocalStorageValue('mbcrm/token', data?.result?.token);
      this.commonService.setLocalStorageValue('userId', data?.result?.userDetail?.id);
      this.commonService.setLocalStorageValue('CRM', 'true');
      //Get Unique Sites from user's site details object
      this.commonService.setLocalStorageValue('userSites', JSON.stringify(this.getUniqueSitesFromToken(data?.result?.userSites, "siteId")).replaceAll("siteId", "id"));
      this.commonService.setLocalStorageValue('userProjects', JSON.stringify(data?.result?.userSites));
      if(data?.result?.userSites?.length==1){
      this.commonService.setLocalStorageValue('siteId', data?.result?.userSites[0]?.siteId);
      }
      this.router.navigate(['sales/booking']);
    }, error => {
      // Check if the error is due to invalid username or password
      if (error instanceof HttpErrorResponse && error.status === 401) {
        this.errorMessage = 'Invalid username or password.';
      } else {
        this.errorMessage = 'An error occurred during login.';
      }
    });
  }



  /**
   * Gets unique sites from user login response
   * @param userSites Total Sites records from which unique records have to be searched
   * @param uniqueFieldToSearch Unique field on basis of which field it has to be searched
   * @returns Returns array of Unique site records
   */
  getUniqueSitesFromToken(userSites: any, uniqueFieldToFind: any) {
    let UniqueSites: any[] = [];
    let isExists: boolean = false

    userSites.forEach((site: any) => {
      isExists = false;

      UniqueSites.forEach(currUniquesite => {
        if (site[uniqueFieldToFind] == currUniquesite[uniqueFieldToFind])
          isExists = true;
      })
      if (!isExists) UniqueSites.push({ "siteId": site.siteId, "siteName": site.siteName })
    });

    return UniqueSites;
  }
}


