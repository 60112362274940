import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService,
    private myRoute: Router) {
  }

  /** allows valid user to navigate to given route
   * @param next ActivatedRouteSnapshot can also be used to traverse the router state tree.
   * @param state This is a tree of activated route snapshots.
   * @returns Wrong User Not Navigate In This Page
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.loggedIn()) {
      return true;
    } else {
      this.myRoute.navigate(["/login"]);
      return false;
    }
  }

}
