import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonService } from '../services/common/common.service';
import { menuList } from './menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrl: './left-nav.component.scss',
  animations: [
    trigger('slidein', [
      transition(':enter', [
        // when ngif has true
        style({ transform: 'translateX(-100%)' }),
        animate(250, style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        // when ngIf has false
        animate(250, style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class LeftNavComponent implements OnInit{

  isHovered: boolean = false;
  menu : any[]=[];
  constructor(public commonService:CommonService, public router:Router){

  }

  ngOnInit(): void {
    this.menu=menuList;
  }

  /**
   * method is used to hide on initial load left navigation bar when screen is in mobile view
   * @param url
   */
  hide(url:any){
    if(this.commonService?.isMobileDevice){
      this.commonService.isHidden=true;
    }
    this.router.navigate(url);
  }
}
