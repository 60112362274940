<div class="back-img">
      <div class="row" style="--bs-gutter-x: 0rem;--bs-gutter-y:0rem;">
        <div class="col-md-8 offset-md-2">
          <div class="login-form">
    <div class="login-layout">
        <div class="mainLogo"> <img src="../../../assets/images/Manu bhoomi new logo.svg" height="125px" alt
                routerLink="/booking" style="font-size: 30px;"> </div>
        <div class="text-center welcomeLabel"> Welcome </div>
        <form class="form-auth-small m-t-20"  #f="ngForm">
            <label></label>
            <div class="loginform-field d-flex align-items-center">
                <input type="text" class="form-control round" name="email" required placeholder="User Name/Email Address"
                [(ngModel)]="loginUserData.email" #username="ngModel">
            </div>
            <br>
            <div class="loginform-field d-flex align-items-center pswdInput mb-3">
                <i [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (mouseup)="showPassword()" (mousedown)="showPassword()"></i>
                <input  [type]="show_button ? 'text' : 'password'" required class="form-control round" name="password" placeholder="Password"
                [(ngModel)]="loginUserData.password" #password="ngModel"   style="color: black;">
            </div>
            <!-- <p class="forgot">Forgot Password</p> -->
            <div *ngIf="errorMessage" class="error-message mb-3">{{ errorMessage }}</div>
            <button class="btn btnlogin" [disabled]= "!f.form.valid" (click)="Submit()">Login</button>
        </form>
    </div>
</div>
</div>
</div>
</div>