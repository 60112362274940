import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { ModalService } from './services/common/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'manubhoomi-FE';
  hednav = false
  constructor(private router:Router, private authService:AuthService, public modalService:ModalService){}

  ngOnInit() :void{

  }

  /**
   * method used to enable and disable the hedNav conditionally
   * @returns
   */
  isLoginPage() : boolean {
    if(this.router.url === '/login'){
      this.hednav = true;
    }
    else{
      this.hednav = false;
    }
    return this.router.url === '/login';

  }

}
