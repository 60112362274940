
export const menuList = [{

  /**
   * Sale Module Components
   */
  id: 1,
  name: 'Sales',
  icon: '../../assets/images/sale.svg',
  subMenu: [{
    id: 1,
    name: 'Booking',
    url: ['/sales/booking'],
  },
  {
    id: 2,
    name: 'Payment',
    url: ['/sales/payment']
  },
  {
    id: 2,
    name: 'Payment-Plan',
    url: ['/sales/paymentPlan']
  },
  {
    id: 2,
    name: 'Client',
    url: ['/sales/customer']
  },
  {
    id: 2,
    name: 'Allotment',
    url: ['/sales/allotment']
  }
  ]
},

/**
 * Accounts Module Components
 */
{
  id: 2,
  name: 'Account',
  icon: '../../assets/images/accounticon.svg',
  subMenu: [{
    id: 1,
    name: 'Reconcilation',
    url: ['/accounts/reconcilation'],
  },
  ]
},

/**
 * Manage Module Components
 */
{
  id: 3,
  name: 'Manage',
  icon: '../../assets/images/manageIcon.svg',
  subMenu: [{
    id: 1,
    name: 'Unit Description',
    url: ['/manage/unitDescription'],
  },
  {
    id: 2,
    name: 'Unit Category',
    url: ['/manage/unitCategory'],
  },
  ]
}
]
