<div class="d-flex" id="sidebar">
    <div [ngClass]="{'logo-area':true}"><span><img src="../../assets/images/Manu bhoomi new logo.svg"
                style="margin-top: 11px;"></span></div>

    <div class="container-fluid">
        <div class="row me-0">
            <div class="col-md-6 toggle">
                <i [ngClass]="{'breadcum':true}" (click)="toggleSidebar()"><span class="profileImg"><img
                            src="../../assets/images/Toggle icon.svg"
                            style="width: 30px;cursor: pointer; cursor: pointer;" alt=""></span></i>
            </div>
            <div class="col-md-6 branding-area">
                <strong>MANU BHOOMI</strong>
                <i><img src="../../assets/images/bell.svg" class="bellicon" alt=""></i>
                <div class="dropdownMenu">
                    <div class="profile-container">
                        <img src="../../assets/images/profileDefaultPic.svg" class="profile-avatar" alt="">
                        <div class="dropdownMenu-content">
                            <span class="dropdownMenu-content-option">Profile</span>
                            <span class="dropdownMenu-content-option" (click)="showSwitchSite()">Switch Site</span>
                            <span class="dropdownMenu-content-option" (click)="logout()">Logout</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-sub-header></app-sub-header>
<app-site-switch *ngIf="commonService.showSiteModal | async"></app-site-switch>