import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class KMLService {

    constructor(private restService: RestService) {

    }

    getKmlFile(filePath: string) {
        return this.restService.getData("1/Dashboard/Phase 1.kml")
    }
}