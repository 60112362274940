import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RestService } from './services/common/rest.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FilterPipe } from './custome-filterpipe';
import { MasterService } from './services/common/master-api.service';
import { SiteSwitchComponent } from './site-switch/site-switch.component';
import { BanksService, UserService } from './services/common';
import { AppInterceptorService } from './shared/app-interceptor.service';
import { JwtService } from './auth/jwtservice';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ExportService } from './services/common/export.service';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LeftNavComponent,
    LoginComponent,
    SubHeaderComponent,
    SiteSwitchComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule, ReactiveFormsModule,
    NgSelectModule,
    ToastrModule.forRoot({
      toastClass: 'ngx-toastr',
      closeButton: true,
      timeOut: 3000,
      extendedTimeOut: 1000,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    DialogModule,
    ButtonModule,
    DropdownModule,
    MenubarModule,
    NgxSpinnerModule
  ],

  providers: [DatePipe,AuthGuard, RestService, MasterService, FilterPipe, BanksService, UserService, JwtService, JwtHelperService, provideAnimationsAsync(),
    AppInterceptorService, { provide: HTTP_INTERCEPTORS, useClass: AppInterceptorService, multi: true }, ExportService
    ],
  bootstrap: [AppComponent]
})

export class AppModule { }
