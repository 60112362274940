<div class="modal" [ngStyle]="isVisible?{'display':'block','background-color':'rgba(0, 0, 0, 0.5)'}:{'display':'none'}"
  tabindex="-1" role="dialog" aria-labelledby="siteSwitchModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="login">
          <div class="logo1"> <img src="../../assets/images/Manu bhoomi new logo.svg" height="125px" alt
              routerLink="/booking" style="font-size: 30px;"> </div>
          <div class="text-center mt-4 name" style="color: black;">
            <h2>Select Site</h2>
          </div>
          <form class="form-auth-small m-t-20" #f="ngForm">
            <label></label>
            <div class="form-field">
              <ng-select type="text" class="ngDropdown" style="width:40vh;" [(ngModel)]="selectedSiteId"
                [ngModelOptions]="{standalone: true}">
                <ng-option [value]="-1" style="text-align: center;">Select Site</ng-option>
                <ng-option style="text-align: center;" *ngFor="let option of options" [value]="option?.id">{{
                  option?.siteName }}</ng-option>
              </ng-select>
            </div>
            <br>
            <button class="btn btnContinue" [disabled]="isValidSite(selectedSiteId)"
              (click)="updateCurrentSite()">Continue</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>