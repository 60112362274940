<nav id="sidebarMenu" class="d-lg-block sidebarMenu" *ngIf="!commonService?.isHidden" @slidein>

  <div class="menuCell" (mouseover)="isHovered = true" (mouseout)="isHovered = false" *ngFor="let m of menu">
    <a class="list-group-item list-group-item-action py-2" aria-current="true"> &nbsp; &nbsp;
      <img src="{{m.icon}}" class="initial-icon" style="width: 25px; height: 25px;">
      <img src="../../assets/images/bread.svg" style="width:10px;">
      <span style="padding-right: 13px" (click)="hide(m.url)">{{m.name}}</span>
      <div class="hover-content">
        <span *ngFor="let sub of m.subMenu">
          <h6 (click)="hide(sub.url)">{{sub.name}}</h6>
        </span>
      </div>
    </a>
  </div>
</nav>