import { Component, Input } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-site-switch',
  templateUrl: './site-switch.component.html',
  styleUrl: './site-switch.component.scss',
})
export class SiteSwitchComponent {
  options: any = [];
  selectedSiteId: any = -1;
  @Input() isVisible: boolean = true;

  constructor(
    private commonService: CommonService,
    public ngxSpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.ngxSpinnerService.hide();
    var siteOptions = this.commonService.getValueFromLocalStorage('userSites');
    this.options = siteOptions ? JSON.parse(siteOptions) : this.commonService.getUserSitesFromAPI(this.commonService.getLoggedInUserId());

    //Get current site value to display in site dropdown to be selected by default
    this.selectedSiteId = this.commonService.getCurrentSiteId()
    if (!this.selectedSiteId) {
      this.selectedSiteId = -1;
    }
    else {
      //if site is selected then site id will be set as name rather then id so that dropdown modal should display site name
      //However while we would be saving in local storage we will save the actual siteId
      this.selectedSiteId = this.options.find((x: any) => x.id == this.selectedSiteId)?.siteName
    }

  }

  /**
   * Updates siteId in local storage to refresh projects and project data
   */
  updateCurrentSite() {
    //Sets selected Site Id in local storage. if we have set the selectedSiteeId as name (to display name in dropdown) then
    //get the siteid from options based on site name and set Id in local storage
    if (isNaN(this.selectedSiteId))
      this.commonService.setLocalStorageValue('siteId', this.options.find((x: any) => x.siteName == this.selectedSiteId)?.id)
    else
      this.commonService.setLocalStorageValue('siteId', this.selectedSiteId);

    this.isVisible = false;
    this.commonService.showSiteModal.next(false)

    //Once we have set update the site then project list has to be refreshed based on selected site
    this.commonService.getUserPojectListBySiteId(this.selectedSiteId)

    //emits the current site so that projects can be refreshed on sub header and data can be refreshed
    this.commonService.refreshProject.next(true)
  }

  /**
   * Checks if valid site is selected or not. When user has selected "Please select a site then default value is "
   * @param value
   * @returns
   */
  isValidSite(value: any) {
    if (value == null || value == undefined || value < 0)
      return true
    else
      return false
  }
}
